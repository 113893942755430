import React from 'react'
import {
    Button,
    Container,
    LoginButton,
    LoginForm,
    LoginPicture,
    Mail,
    MailInput,
    Password,
    PasswordInput,
    Title,
    Wrap,
} from './styled.js'
import {withRouter} from 'react-router'
import LoginImage from './LoginImage.png'
import {LoginRequest} from '../../store/actions/auth.actions.js'
import {connect} from 'react-redux'
import Spinner from "react-bootstrap/Spinner"

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            submitted: false,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isLoggedIn) {
            this.props.history.replace('/');
        }
    }

    handleChange = event => {
        const {name, value} = event.target;
        this.setState({[name]: value})
    };

    handleSubmit = event => {
        const {login, history} = this.props;
        const {email, password} = this.state;

        event.preventDefault();
        this.setState({submitted: true, loading: true});

        login(email, password, history)
    };

    render() {
        const {loading} = this.props;
        const {email, password} = this.state;
        return (
            <React.Fragment>
                <Container>
                    <Wrap>
                        <LoginPicture>
                            <img src={LoginImage} alt="Login"/>
                        </LoginPicture>
                        <LoginForm onSubmit={this.handleSubmit}>
                            <Title>Admin Login</Title>
                            <Mail data-validate="admin@admin.com">
                                <MailInput
                                    type="text"
                                    name="email"
                                    placeholder="Email"
                                    value={email}
                                    autoComplete="username"
                                    onChange={this.handleChange}
                                />
                            </Mail>
                            <Password data-validate="admin">
                                <PasswordInput
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    value={password}
                                    autoComplete="current-password"
                                    onChange={this.handleChange}
                                />
                            </Password>
                            <LoginButton>
                                <Button block bsSize="large" disabled={loading} type="submit">
                                    Login&nbsp;
                                    {
                                        loading ? (
                                            <Spinner animation="border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                        ) : null
                                    }
                                </Button>
                            </LoginButton>
                        </LoginForm>
                        {/* <button onClick={() => this.handleChangePass()}>DO</button> */}
                    </Wrap>
                </Container>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        isLoggedIn: state.auth.isLoggedIn,
    }
};

const mapActionsToProps = dispatch => ({
    login: (email, password, history) => dispatch(LoginRequest(email, password, history))
});

export default withRouter(
    connect(
        mapStateToProps,
        mapActionsToProps,
    )(Login),
)
