import { signIn, signOut, getCurrentUser, fetchAuthSession } from 'aws-amplify/auth'

export const login = async (username, password) => {
    return await signIn({ username, password })
};

export const logout = () => {
    return signOut()
};

export const checkAuth = () => {
    return getCurrentUser()
};

export const getIdToken = async () => {
    try {
        const { idToken } = (await fetchAuthSession({})).tokens ?? {};
        return idToken
    } catch (err) {
        console.log(err);
    }
};