import * as types from '../actions/types'

const initialState = {
    user: {},
    token: {},
    isLoggedIn: false,
    loading: false,
    error: {},
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CHECK_AUTH_REQUEST: {
            return {
                ...state,
            }
        }
        case types.CHECK_AUTH_SUCCESS: {
            return {
                ...state,
                user: action.user,
                isLoggedIn: true,
            }
        }
        case types.CHECK_AUTH_FAILURE: {
            return {
                ...state,
                isLoggedIn: false,
                error: action.error,
            }
        }
        case types.LOGIN_REQUEST: {
            return {
                ...state,
            }
        }
        case types.LOGIN_SUCCESS: {
            return {
                ...state,
                token: action.token,
                isLoggedIn: true,
            }
        }
        case types.LOGIN_FAILURE: {
            return {
                ...state,
                isLoggedIn: false,
                error: action.error,
            }
        }
        case types.LOGOUT_REQUEST: {
            return {
                ...state,
            }
        }
        case types.LOGOUT_SUCCESS: {
            return {
                ...state,
                user: {},
                token: {},
                isLoggedIn: false,
            }
        }
        case types.LOGOUT_FAILURE: {
            return {
                ...state,
                isLoggedIn: true,
                error: action.error,
            }
        }
        case types.TOGGLE_AUTH_LOADING: {
            return {
                ...state,
                loading: !state.loading,
            }
        }
        default:
            return state
    }
};
export default authReducer
