import React from "react";
import {Button} from "react-bootstrap";
import {ActionsBlock, SaveContainer} from "./styled";
import Spinner from "react-bootstrap/Spinner";
import ConfirmationDialog from "../ConfirmationDialog";



const Actions = ({
    openText,
    opened,
    saving,
    editing,
    removing,
    toggleEdit,
    removeIncident,
    toggleConfirm,
    item = null,
    inactive = false,
}) => (
    <ActionsBlock>
        { opened ? (
            <SaveContainer>
                <Button variant="primary" type="submit" className="save">
                    Save&nbsp;
                    {
                        saving ? (
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        ) : null
                    }
                </Button>
                &nbsp;
                <Button variant="secondary" type="reset" onClick={() => toggleEdit(item)}>
                    Cancel
                </Button>
            </SaveContainer>
        ) : (
            <Button variant="info" type="button" disabled={removing || editing || inactive} onClick={() => toggleEdit(item)}>
                {openText}
            </Button>
        )}

        {removeIncident && !opened && (
            <>
                &nbsp;
                <Button type="button"
                        variant="danger"
                        disabled={removing || editing || inactive}
                        onClick={() => toggleConfirm(item)}>
                    <i className="fa fa-trash"/>
                </Button>
                {removing && (
                    <ConfirmationDialog>
                        <p>Are you sure you want to delete incident ?</p>
                        <Button type="button" variant="danger" onClick={removeIncident}>Yes</Button>
                        &nbsp;
                        <Button type="button" onClick={toggleConfirm}>No</Button>
                    </ConfirmationDialog>
                )}
            </>
        )}
    </ActionsBlock>
);

export default Actions
