import * as types from './types'

export const CheckAuthRequest = () => ({
    type: types.CHECK_AUTH_REQUEST,
});

export const CheckAuthSuccess = user => ({
    type: types.CHECK_AUTH_SUCCESS,
    user,
});

export const CheckAuthFailure = error => ({
    type: types.CHECK_AUTH_FAILURE,
    error,
});

export const LoginRequest = (email, password, history) => ({
    type: types.LOGIN_REQUEST,
    data: {email, password, history},
});

export const LoginSuccess = idToken => ({
    type: types.LOGIN_SUCCESS,
    idToken,
});

export const LoginFailure = error => ({
    type: types.LOGIN_FAILURE,
    error,
});

export const LogoutRequest = () => ({
    type: types.LOGOUT_REQUEST,
});

export const LogoutSuccess = () => ({
    type: types.LOGOUT_SUCCESS,
});

export const LogoutFailure = error => ({
    type: types.LOGOUT_FAILURE,
    error,
});

export const ToggleAuthLoading = () => ({
    type: types.TOGGLE_AUTH_LOADING,
});